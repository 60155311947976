import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const NoPortfolioSelected = () => {
  const { t } = useModifiedTranslation();
  return (
    <ErrorMessage header={t("messages.noPortfolioSelected")}>
      {t("messages.noPortfolioSelectedInfo")}
    </ErrorMessage>
  );
};
