import { gql, useQuery } from "@apollo/client";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import moment from "moment";

const GET_CONTACTS_QUERY = gql`
  query ($extId: String, $today: String, $nexBusinessDay: String, $settlementStartDateT2: String) {
    contacts(extId: $extId) {
      portfolios {
        portfolioReport(
          reportDate: $today
          calculateExpectedAmountBasedOpenTradeOrders: true
          calculateExpectedAmountBasedUnsettledTransactions: true
          useLatestPositionsAmount: true
          adjustPositionsBasedOnOpenTradeOrders: false
        ) {
          accountItems {
            currencyId
            currency {
              securityCode
              __typename
            }
            accountName
            amountBeforeUnsettledTransactions
            expectedAmount
            amountAfterOpenTradeOrders
            balanceAccCurr
            buyOpenTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "4"
            ) {
              buyOpenTradeAmount: tradeAmount
              __typename
            }
            buyAcceptedTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "5"
            ) {
              buyAcceptedTradeAmount: tradeAmount
              __typename
            }
            buyExecutableTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "1"
            ) {
              buyExecutableTradeAmount: tradeAmount
              __typename
            }
            buySentToExecutionTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "10"
            ) {
              buySentToExecutionTradeAmount: tradeAmount
              __typename
            }
            buyInExecutionTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "6"
            ) {
              buyInExecutionTradeAmount: tradeAmount
              __typename
            }
            buyPartiallyExecutedTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "12"
            ) {
              buyPartiallyExecutedTradeAmount: tradeAmount
              __typename
            }
            buyExecutedMarketTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "7"
            ) {
              buyExecutedMarketTradeAmount: tradeAmount
              __typename
            }
            sellUnsettledTransactionsT1: transactions(
              aggregationMode: 2
              settlementStartDate: $nexBusinessDay
              settlementEndDate: $nexBusinessDay
              typeCodes: ["S", "RED"]
              status: "OK"
            ) {
              sellUnsettledTradeAmountT1: tradeAmount
              __typename
            }
            sellUnsettledTransactionsT2: transactions(
              aggregationMode: 2
              settlementStartDate: $settlementStartDateT2
              typeCodes: ["S", "RED"]
              status: "OK"
            ) {
              sellUnsettledTradeAmountT2: tradeAmount
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      name
      externalId
      contactId
      __typename
    }
    __typename
  }
`;

interface AccountItem {
  accountName: string;
  currencyId?: number | undefined;
  currency: {
    securityCode: string;
  };
  amountBeforeUnsettledTransactions?: number | undefined;
  expectedAmount?: number | undefined;
  amountAfterOpenTradeOrders?: number | undefined;
  balanceAccCurr: number;
  buyOpenTradeOrder: { buyOpenTradeAmount: number }[];
  buyAcceptedTradeOrder: { buyAcceptedTradeAmount: number }[];
  buyExecutableTradeOrders: { buyExecutableTradeAmount: number }[];
  buySentToExecutionTradeOrder: { buySentToExecutionTradeAmount: number }[];
  buyInExecutionTradeOrders: { buyInExecutionTradeAmount: number }[];
  buyPartiallyExecutedTradeOrders: { buyPartiallyExecutedTradeAmount: number }[];
  buyExecutedMarketTradeOrders: { buyExecutedMarketTradeAmount: number }[];
  sellUnsettledTransactionsT1: { sellUnsettledTradeAmountT1: number }[];
  sellUnsettledTransactionsT2: { sellUnsettledTradeAmountT2: number }[];
}

interface Contact {
  portfolios: Portfolio[];
}

interface Portfolio {
  portfolioReport: {
    accountItems: AccountItem[];
  }
}

interface ContactsData {
  contacts: Contact[];
}

export interface Balance {
  trading: { currency: string, amount: number }[];
  withdrawal: { currency: string, amount: number }[];
}
interface Balances { 
  [key: string]: Balance
}

export const useGetAvailableBalancesExperimental = (
  extId: string | undefined
) => {
  const { data: contactData } = useGetContactInfo();

  const addBusinessDays = (date: Date, daysToAdd: number) => {
    const WEEKEND = [moment().day("Saturday").weekday(), moment().day("Sunday").weekday()]
    let daysAdded = 0;
    let momentDate = moment(new Date(date));
    while (daysAdded < daysToAdd) {
      momentDate = momentDate.add(1, 'days');
      if (!WEEKEND.includes(momentDate.weekday())) {
        daysAdded++
      }
    }

    return momentDate;
  }

  const currentDate = new Date();
  const nextBusinessDay = addBusinessDays(currentDate, 1);
  const settlementStartDate = addBusinessDays(currentDate, 2);

  const variables: { 
    extId: string | undefined; 
    today: string; 
    nexBusinessDay: string; 
    settlementStartDateT2: string; 
  } = {
    extId,
    today: currentDate.toISOString().split('T')[0],
    nexBusinessDay: nextBusinessDay.format('YYYY-MM-DD'),
    settlementStartDateT2: settlementStartDate.format('YYYY-MM-DD'),
  };

  const { loading, error, data } = useQuery<ContactsData>(GET_CONTACTS_QUERY, extId ? {
    variables: variables,
  } : { skip: true });

  const balances:Balances = {}

  data?.contacts.forEach((contact: Contact) => {
    contact?.portfolios.forEach((portfolio: Portfolio) => {
      portfolio?.portfolioReport?.accountItems.forEach((item: AccountItem) => {
        const accountNumber = item.accountName.split('-')[0];
        const currency = item.currency.securityCode;

        const tradingAmount = item.balanceAccCurr - (
          (item.buyOpenTradeOrder[0]?.buyOpenTradeAmount || 0) 
          + (item.buyAcceptedTradeOrder[0]?.buyAcceptedTradeAmount || 0)
          + (item.buyExecutableTradeOrders[0]?.buyExecutableTradeAmount || 0)
          + (item.buySentToExecutionTradeOrder[0]?.buySentToExecutionTradeAmount || 0)
          + (item.buyInExecutionTradeOrders[0]?.buyInExecutionTradeAmount || 0)
          + (item.buyPartiallyExecutedTradeOrders[0]?.buyPartiallyExecutedTradeAmount || 0)
          + (item.buyExecutedMarketTradeOrders[0]?.buyExecutedMarketTradeAmount || 0)
          + (item.sellUnsettledTransactionsT2[0]?.sellUnsettledTradeAmountT2 || 0)
        );

        const withdrawalAmount = tradingAmount - (item.sellUnsettledTransactionsT1[0]?.sellUnsettledTradeAmountT1 || 0)

        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }


        balances[accountNumber].trading.push({ currency, amount: tradingAmount });
        balances[accountNumber].withdrawal.push({ currency, amount: withdrawalAmount });
      });
    });
  });

  if (contactData?.accountsCurrencies) {
    Object.keys(contactData?.accountsCurrencies).forEach(accountNumber => {
      contactData?.accountsCurrencies[accountNumber].forEach(currency => {
        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }
  
        if (!balances[accountNumber].trading.find((item) => item.currency === currency)) {
          balances[accountNumber].trading.push({ currency, amount: 0 });
        }
  
        if (!balances[accountNumber].withdrawal.find((item) => item.currency === currency)) {
          balances[accountNumber].withdrawal.push({ currency, amount: 0 });
        }
      });
    });
  }

  return {
    loading,
    error,
    data,
    balances,
  };
};


