import { useState } from "react";
import {SecurityClassType1, SecurityTypeCode } from "api/holdings/types";
import { Option } from "components/Select/Select";
import i18n from "i18next";

export enum EOrderType {
    MARKET_ORDER = "Order type-Market",
    LIMIT_ORDER = "Order type-Limit",
}

export enum EOrderClassType {
    COMPLEX = "Complex",
    NON_COMPLEX = "Non-complex",
}

export enum EOrderExpiration {
    DAY = "Order expiration-Day",
    GOOD_TILL_CANCELLED = "Order expiration-Good til cancelled",
}

const getOrderTypeOptions = (): Option[] => {
    return [
        { id: EOrderType.MARKET_ORDER, label: i18n.t("tradingModal.marketOrder") },
        { id: EOrderType.LIMIT_ORDER, label: i18n.t("tradingModal.limitOrder") },
    ];
};

const getOrderExpirationOptions = (): Option[] => {
    return [
        { id: EOrderExpiration.DAY, label: i18n.t("tradingModal.day") },
        { id: EOrderExpiration.GOOD_TILL_CANCELLED, label: i18n.t("tradingModal.goodTillCancelled”") },
    ];
};

export const useOrderTypeFields = (
    selectedOrderTypeId?: string | undefined,
    selectedOrderExpirationId?: string | undefined,
    initialUnitPrice?: number | undefined,
) => {
    const orderTypeOptions = getOrderTypeOptions();
    const orderExpirationOptions = getOrderExpirationOptions();

    const [orderType, setOrderType] = useState<Option | undefined>(selectedOrderTypeId ?
        orderTypeOptions.find((option: Option) => option.id === selectedOrderTypeId)
        : orderTypeOptions[0]);

    const [orderExpiration, setOrderExpiration] = useState<Option | undefined>(selectedOrderExpirationId ?
        orderExpirationOptions.find((option: Option) => option.id === selectedOrderExpirationId)
        : orderExpirationOptions[0]);

    const [unitPrice, setUnitPrice] = useState<number | undefined>(initialUnitPrice ?? 0);

    const [isConfirmationChecked, setConfirmationIsChecked] = useState(false);

    const handleSetOrderType = (option?: Option) => {
        setOrderType(option);
    };

    const handleSetOrderExpiration = (option?: Option) => {
        setOrderExpiration(option);
    };

    const handleSetUnitPrice = (value?: number) => {
        setUnitPrice(value);
    };

    const isOrderTypeFieldsVisible = (securityType: SecurityTypeCode | undefined): boolean => {
        return securityType ? [
            SecurityTypeCode.EQUITY,
            SecurityTypeCode.ETF,
            SecurityTypeCode.DEBT_INSTRUMENT,
        ].includes(securityType) : false;
    }

    const handleCheckboxChange = (checked: boolean) => {
        setConfirmationIsChecked(checked);
    };

    const getDisclaimerKeys = (classType: string, securityType: SecurityTypeCode | undefined, isCBL: boolean | undefined) => {
        const isComplex = classType === SecurityClassType1.COMPLEX;
        const isTypeEorCE = securityType === SecurityTypeCode.EQUITY || securityType === SecurityTypeCode.ETF;

        let keyPrefix = isCBL ? 'tradingModal.disclaimerCBL' : 'tradingModal.disclaimerOther';

        if (!isCBL) {
            if (isTypeEorCE) {
                keyPrefix = isComplex ? 'tradingModal.disclaimerComplexTypeE_CE' : 'tradingModal.disclaimerNonComplexTypeE_CE';
            } else {
                keyPrefix = isComplex ? 'tradingModal.disclaimerComplex' : 'tradingModal.disclaimerNonComplex';
            }
        }

        const disclaimerKeysMap: { [key: string]: string[] } = {
            'tradingModal.disclaimerCBL': ['first_row', 'second_row', 'third_row', 'fourth_row', 'fifth_row'],
            'tradingModal.disclaimerOther': ['first_row', 'second_row', 'third_row', 'fourth_row', 'fifth_row'],
            'tradingModal.disclaimerNonComplexTypeE_CE': ['first_row', 'second_row', 'third_row', 'fourth_row', 'fifth_row'],
            'tradingModal.disclaimerComplex': ['first_row', 'second_row', 'third_row'],
            'tradingModal.disclaimerComplexTypeE_CE': ['first_row', 'second_row', 'third_row', 'fourth_row'],
            'tradingModal.disclaimerNonComplex': ['first_row', 'second_row', 'third_row', 'fourth_row']
        };

        const translationDisclaimerKeys = disclaimerKeysMap[keyPrefix] || [];

        return {
            keyPrefix,
            translationDisclaimerKeys
        };
    };

    const getFormattedOrderTypeFields = (securityType: SecurityTypeCode | undefined): { tags: string | undefined; unitPrice: string | undefined; } => {
        if (isOrderTypeFieldsVisible(securityType)) {
            let tags:string | undefined = (orderType?.id)?.toString() ?? undefined;

            if (orderExpiration) {
                tags += `,${orderExpiration?.id},Order source-Client Portal`;
            }
    
            const result = {
                tags,
                unitPrice: orderType?.id === EOrderType.LIMIT_ORDER ? unitPrice?.toString() : undefined,
            }
    
            return result;
        }

        return {
            tags: "Order source-Client Portal",
            unitPrice: undefined,
        };
    }

    return {
        orderType: orderType,
        orderTypeOptions: orderTypeOptions,
        setOrderType: handleSetOrderType,

        orderExpiration: orderExpiration,
        orderExpirationOptions: orderExpirationOptions,
        setOrderExpiration: handleSetOrderExpiration,

        unitPrice: unitPrice,
        setUnitPrice: handleSetUnitPrice,

        isConfirmationChecked: isConfirmationChecked,
        setConfirmationIsChecked: handleCheckboxChange,

        getFormattedOrderTypeFields: getFormattedOrderTypeFields,
        isOrderTypeFieldsVisible: isOrderTypeFieldsVisible,

        getDisclaimerKeys: getDisclaimerKeys,
    };
};
