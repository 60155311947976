import { gql, useQuery } from "@apollo/client";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";

const GET_CONTACTS_QUERY = gql`
  query ($extId: String, $tags: [String]) {
  contacts(extId: $extId, tags: $tags) {
    classification {
      code
    }
    profile {
      derivativesExpiryDate: attribute(attributeKey: "contact.T24.DerivativesExpiryDate") {
        attributeKey
        dateValue
      }
      derivativesTestResult: attribute(attributeKey: "contact.T24.DerivativesTestResult") {
        attributeKey
        stringValue
      }
      riskUnderstExpiryDate: attribute(attributeKey: "contact.T24.RiskUnderstExpiryDate") {
        attributeKey
        dateValue
      }
      riskUnderstTestResult: attribute(attributeKey: "contact.T24.RiskUnderstTestResult") {
        attributeKey
        stringValue
      }
      etfExpiryDate: attribute(attributeKey: "contact.T24.ETFExpiryDate") {
        attributeKey
        dateValue
      }
      etfTestResult: attribute(attributeKey: "contact.T24.ETFTestResult") {
        attributeKey
        stringValue
      }
    }
  }
}
`;

export interface FinancialContactsData {
  contacts: FinancialContact[];
}

export interface FinancialProfile {
  derivativesExpiryDate: {
    attributeKey: string;
    dateValue: string | null;
  };
  derivativesTestResult: {
    attributeKey: string;
    stringValue: string | null;
  };
  riskUnderstExpiryDate: {
    attributeKey: string;
    dateValue: string | null;
  };
  riskUnderstTestResult: {
    attributeKey: string;
    stringValue: string | null;
  };
  etfExpiryDate: {
    attributeKey: string;
    dateValue: string | null;
  };
  etfTestResult: {
    attributeKey: string;
    stringValue: string | null;
  };
}

export interface FinancialContact {
  classification: {
    code: string;
  };
  profile: FinancialProfile;
}

export const useGetFinancialProfile = (
  extId: string | undefined,
) => {
  const { selectedContactId } = useGetContractIdData();
  const { companyTag } = useGetContactInfo(false, selectedContactId);

  const variables: { extId: string | undefined; tags?: string } = {
    extId,
  };

  if (companyTag) {
    variables.tags = `Company-${companyTag}`;
  }

  
  const { loading, error, data } = useQuery<FinancialContactsData>(GET_CONTACTS_QUERY, extId ? {
    variables: variables,
  } : { skip: true });

  const financialProfile = data?.contacts[0]?.profile ?? undefined;

  return {
    loading,
    error,
    data,
    financialProfile,
  };
};


