import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";

export const useDetectActiveUserType = () => {
  const { data: contactData } = useGetContactInfo();
  const { selectedContactId } = useGetContractIdData();
  const { data: selectedContactData } = useGetContactInfo(true, selectedContactId);

  const isAnotherUser = (contactData?.contactId && selectedContactId) 
    ? contactData?.contactId?.toString() !== selectedContactId?.toString() : false

  const isAnotherPrivateUser = isAnotherUser && selectedContactData?.juridicalCode === "PE";
  const isAnotherLegalUser = isAnotherUser && selectedContactData?.juridicalCode === "CO";

  return { isAnotherPrivateUser, isAnotherLegalUser, isAnotherUser }
};
