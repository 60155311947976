import { ReactNode } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useDetectActiveUserType } from "hooks/useDetectActiveUserType";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { filterNoBrokerage } from "../../services/permissions/usePermission";
import { NoPortfolios } from "./components/NoPortfolios";

interface PortfolioGuardProps {
  children: ReactNode;
}

export const PortfolioGuard = ({ children }: PortfolioGuardProps) => {
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const { isAnotherPrivateUser } = useDetectActiveUserType();

  if (portfolios.length === 0) {
    return <NoPortfolios />;
  }

  if (isAnotherPrivateUser) {
    const filteredOutBrokearage = filterNoBrokerage(portfolios)

    if (!filteredOutBrokearage.length) {
      return <NoPortfolios />;
    } 
  }

  return <>{children}</>;
};
